import React from "react";
import "./style.css";

export default function App() {
  return (
    <div className="App">
      <h1>RRLP</h1>
    </div>
  );
}
